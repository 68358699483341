/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { Location, UserLocation } from 'api/auth';

export class UserRegistrationLocationModel {
  constructor(dto: UserLocation) {
    this.anonymizerType = dto.anonymizerType;
    this.locationDt = dto.locationDt;
    this.locationDto = dto.locationDto;
    this.locationFound = dto.locationFound;
  }

  anonymizerType: string;
  locationDt?: string;
  locationDto?: Location;
  locationFound: boolean;
}
