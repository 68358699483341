/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export class DashboardStatsModel {
  constructor(dto: Record<string, number>) {
    this.totalUsers = getValue(dto, 'users_value[]');
    this.pendingSumsub = getValue(dto, 'users_sumsub_pending_value[]');
    this.pendingApproval = getValue(dto, 'users_pending_value[]');
    this.approvedUsers = getValue(dto, 'users_approved_value[]');
    this.activeUsers = getValue(dto, 'activeUsers');
  }

  totalUsers: number;
  pendingSumsub: number;
  pendingApproval: number;
  approvedUsers: number;
  activeUsers: number;
}

function getValue(dto: Record<string, number | string>, key: string) {
  const val = dto[key];

  if (!val || val === 'NaN' || Number.isNaN(val)) {
    return 0;
  }

  return Number(val);
}
