/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Checkbox, Popover } from 'antd';
import cn from 'classnames';

import styles from './index.module.css';
import FormLabel from '../form-label';
import Image from 'components/core/image';
import Button from 'components/core/button';
import SearchField from 'components/core/search-field';
import { ReactComponent as ArrowIcon } from 'assets/images/icons/arrow.svg';
import { SelectItemModel } from 'models/form/SelectItemModel';

type Props<T> = {
  title: string;
  entitiesName: string;
  entitiesCount?: string;
  placeholder?: string;
  required?: boolean;
  collapse?: boolean;
  options: SelectItemModel<T>[];
  selected: SelectItemModel<T>[] | undefined;
  onSelect: (values: SelectItemModel<T>[]) => void;
};

const FormListMultiSelect = <T extends object | string | number | boolean> (props: Props<T>) => {
  const {
    title,
    entitiesName,
    entitiesCount,
    placeholder,
    required,
    collapse,
    options,
    selected,
    onSelect,
  } = props;
  const { formatMessage } = useIntl();

  const [visible, setVisible] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const selectedIds = useMemo(() => selected?.map((item) => item.id), [selected]);
  const selectedIdsSet = useMemo(() => new Set(selectedIds), [selectedIds]);
  const selectedOptions = useMemo(
    () => options.filter(item => selectedIdsSet.has(item.id)),
    [options, selectedIdsSet]);

  const toggleCollapse = () => {
    setVisible((prev) => !prev);
  };

  const togglePopover = (value: boolean) => {
    setIsOpen(value);
  };

  const searchValue = (value: string) => {
    setSearchQuery(value);
  };

  const clickSearchField = (e: MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (!isOpen) setIsOpen(true);
  };

  const onChange = useCallback(
    (values: string[] | number[]) => {
      const selectedValues = options.filter((item) =>
        values.find((value) => value === item.id),
      );
      onSelect(selectedValues);
    },
    [onSelect, options],
  );

  const selectAll = useCallback(() => onSelect(options), [onSelect, options]);

  const deselectAll = useCallback(() => onSelect([]), [onSelect]);

  const selectedItems = selectedOptions.map((item) => {
    const { id, label, icon } = item;
    const iconElement = typeof icon === 'string' ? (
      <Image src={icon} width={20} height={20} />
    ) : (
      icon
    );

    return <Checkbox
      key={`selected-${id}`}
      value={id}
      className={styles['checkbox-label']}
    >
      <div className={styles.label}>
        {icon ? iconElement : null}
        <div>{label}</div>
      </div>
    </Checkbox>;
  });

  const allItems = options.map((item) => {
    const { id, label, icon } = item;
    const isSearchableItem = label.toLowerCase()
                                  .includes(searchQuery.toLowerCase());
    const iconElement = typeof icon === 'string' ? (
      <Image src={icon} width={20} height={20} />
    ) : (
      icon
    );

    return <Checkbox
      key={`all-${id}`}
      value={id}
      className={cn(styles['checkbox-label'],
        { [styles['checkbox-hidden']]: !isSearchableItem },
      )}
    >
      <div className={styles.label}>
        {icon ? iconElement : null}
        <div>{label}</div>
      </div>
    </Checkbox>;
  });

  const itemsCount = entitiesCount && selectedIds && selectedIds.length !== 0 && (
    <div className={styles.count}>
      {`${selectedIds.length} ${entitiesCount}`}
    </div>
  );

  const popoverContent =
    <div className={styles.dropdown}>
      <div className={styles['dropdown-btn-row']}>
        <Button
          variant={'secondary'}
          size={'small'}
          className={styles['dropdown-btn']}
          onClick={selectAll}
        >
          {formatMessage({
            id: 'common.multi_select_dropdown.select_all',
            defaultMessage: 'Select all',
          })}
        </Button>
        <Button
          variant={'secondary'}
          size={'small'}
          className={styles['dropdown-btn']}
          onClick={deselectAll}
        >
          {formatMessage({
            id: 'common.multi_select_dropdown.deselect_all',
            defaultMessage: 'Deselect all',
          })}
        </Button>
      </div>

      <div className={styles['list-container']}>
        <Checkbox.Group value={selectedIds} onChange={onChange}>
          <div className={styles['list']}>
            <div className={styles['text']}>
              <div>
                {formatMessage({
                  id: 'common.multi_select_dropdown.selected',
                  defaultMessage: 'Selected',
                })}
              </div>
              <div className={styles['text-divider']} />
            </div>

            <div className={styles.labels}>{selectedItems}</div>
          </div>
        </Checkbox.Group>

        <Checkbox.Group value={selectedIds} onChange={onChange}>
          <div className={styles['list']}>
            <div className={styles['text']}>
              <div>
                {formatMessage(
                  {
                    id: 'common.multi_select_dropdown.all',
                    defaultMessage: 'All {entities}',
                  },
                  { entities: entitiesName },
                )}
              </div>
              <div className={styles['text-divider']} />
            </div>

            <div className={styles.labels}>{allItems}</div>
          </div>
        </Checkbox.Group>
      </div>
    </div>;

  return (
    <div>
      <div className={styles.title}>
        {title && <FormLabel text={title} required={required} />}

        {collapse && (
          <button
            className={cn(styles.btn, !visible && styles.content_hidden)}
            onClick={toggleCollapse}
          >
            <ArrowIcon />
          </button>
        )}
      </div>

      {visible && (
        <Popover
          open={isOpen}
          onOpenChange={togglePopover}
          content={popoverContent}
          trigger="click"
          arrow={false}
          placement="bottomLeft"
          // destroyTooltipOnHide
        >
          <SearchField
            value={searchQuery}
            onChange={(v) => searchValue(v)}
            onClick={(e) => clickSearchField(e)}
            placeholder={placeholder}
            variant={'secondary'}
          />
          {itemsCount}
        </Popover>
      )}
    </div>
  );
};

export default FormListMultiSelect;
