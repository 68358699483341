/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect, useMemo, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { UploadFile } from 'antd';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import joi from 'joi';
import { debounce } from 'lodash';

import { useBaseStores } from 'providers/BaseStoresProvider';
import {
  CreateQuest,
  PlatformTypeDto,
  QuestStatusDto,
  CreateQuestStep,
  TimerTypeDto,
  UpdateQuest,
} from 'api/quests';
import { useCountries } from 'hooks/countries';
import { useIntlValidation } from 'hooks/intl/validation';
import { AdminQuestsModel } from 'models/AdminQuestModel';
import { SelectItemModel } from 'models/form/SelectItemModel';
import { CreateModal } from './CreateModal';
import { EditEvents } from './EditQuests';
import { transformFromBackendTimer } from 'utils/dates';

export type isInstallEvent = { isInstall?: boolean };

export type CreateQuestForm = {
  name: string;
  description: string;
  id: string;
  reward: number;
  budget: number;
  cpi: number;
  delay: number;
  templateUrl: string;
  currency: string;
  events: (CreateQuestStep & isInstallEvent)[];
  startedAt: Date;
  expiredAt: Date;
  accountId: string;
  status: QuestStatusDto;
  image: UploadFile;
  active: boolean;
  platforms: SelectItemModel<string>[];
  countries: SelectItemModel<string>[];
  timerType: SelectItemModel<string>;
  timer?: number;
};

const enum Step {
  Create,
  EditEvents,
}

const platforms = [
  {
    id: PlatformTypeDto.ANDROID,
    label: 'Android',
    value: PlatformTypeDto.ANDROID,
  },
  {
    id: PlatformTypeDto.WEB,
    label: 'Web',
    value: PlatformTypeDto.WEB,
  },

  {
    id: PlatformTypeDto.IOS,
    label: 'IOS',
    value: PlatformTypeDto.IOS,
  },
];

type Props = {
  quest?: AdminQuestsModel;
  onCreate: (quest: CreateQuest | UpdateQuest) => Promise<void>;
  onDelete?: (questId: string) => void;
  onClose?: () => void;
};

const SidebarQuestCreate = observer(({ quest, onCreate, onDelete }: Props) => {
  const intl = useIntl();

  const { accountsStore } = useBaseStores();
  const [step, setStep] = useState(Step.Create);

  const { validationOptions } = useIntlValidation();
  const { countriesArray } = useCountries();

  const formScheme = useMemo(
    () =>
      joi.object<CreateQuestForm>({
        id: joi.string(),
        timer: joi.string().empty(''),
        events: joi.any(),
        name: joi.string().empty('').required(),
        accountId: joi.string().empty('').required(),
        currency: joi.string().empty('').required(),
        templateUrl: joi.string().required(),
        status: joi.string(),
        description: joi.string().empty('').max(1000),
        timerType: joi.object().required(),
        image: quest ? joi.object().empty(undefined) : joi.object().required(),
        platforms: joi.array().min(1).required(),
        countries: joi.array(),
        active: joi.boolean(),
        budget: joi.number().empty('').min(0).max(Number.MAX_SAFE_INTEGER),
        reward: joi.number().empty('').min(0).max(Number.MAX_SAFE_INTEGER),
        delay: joi.number().min(0).max(Number.MAX_SAFE_INTEGER).required(),
        cpi: joi
        .number()
        .empty('')
        .min(0)
        .max(Number.MAX_SAFE_INTEGER)
        .required(),
        startedAt: joi.date().required(),
        expiredAt: joi
        .date()
        .required()
        .when('startedAt', {
          is: joi.exist(),
          then: joi.date().greater(joi.ref('startedAt')).messages({
            'date.greater': 'The End Date must be greater than Start Date',
          }),
        }),
      }),
    [quest],
  );

  const timersTypes = [
    {
      id: TimerTypeDto.UNIVERSAL,
      label: intl.formatMessage({
        id: 'sidebar.create_quest.universal',
        defaultMessage: 'Universal timer',
      }),
      value: TimerTypeDto.UNIVERSAL,
    },
    {
      id: TimerTypeDto.SEPARATE,
      label: intl.formatMessage({
        id: 'sidebar.create_quest.separate',
        defaultMessage: 'Separate timer',
      }),
      value: TimerTypeDto.SEPARATE,
    },
  ];

  const countryOptions = countriesArray.map(country => {
    const { alpha2Code, name, flagClassName } = country;
    return {
      id: alpha2Code,
      label: name,
      value: alpha2Code,
      icon: <span className={flagClassName}></span>,
    };
  });

  const form = useForm<CreateQuestForm>({
    resolver: joiResolver(formScheme, validationOptions),
    mode: 'onChange',
    defaultValues: quest
      ? {
        id: quest.id,
        name: quest.name,
        description: quest.description,
        reward: quest.reward,
        budget: quest.budget,
        delay: quest.delay,
        cpi: quest.cpi,
        platforms:
          quest.platforms?.map((value) =>
            platforms.find(({ value: v }) => value === v),
          ) || [],
        countries:
          quest.countries?.map((alpha2code) =>
            countryOptions.find(item => alpha2code === item.id),
          ) || [],
        templateUrl: quest.templateUrl,
        currency: quest.currency,
        accountId: quest.accountId,
        startedAt: quest.startedAt ? new Date(quest.startedAt) : undefined,
        expiredAt: quest.expiredAt ? new Date(quest.expiredAt) : undefined,
        status: quest.status,
        active: quest.active,
        timerType: timersTypes.find(({ value }) => value === quest.timerType),
        timer: transformFromBackendTimer(quest.timer).value as any,
        events:
          quest.questSteps?.map((event) => ({
            ...event,
            timer: transformFromBackendTimer(event.timer || undefined)
              .value as any,
            //@ts-ignore
            eventId: event.events?.[0]?.event_id,
            //@ts-ignore
            isInstall: event.events?.[0]?.event_id === 'install',
          })) || [],
      }
      : {
        events: [
          {
            name: 'Install',
            //@ts-ignore
            eventId: '',
            events: [{ event_id: 'install' }],
            exclusive: false,
            timer: undefined,
            reward: undefined,
            isInstall: true,
            position: 0,
          },
        ],
        reward: 0,
        active: true,
        timerType: timersTypes[0],
      },
  });
  const { control, resetField, setValue, watch } = form;

  // useEffect(() => {
  //   const subscription = watch((formValues) => {
  //     console.log('Form State Changed:', formValues);
  //   });
  //
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  const [accountId, timerType, timer, questEvents] = useWatch({
    control,
    name: ['accountId', 'timerType', 'timer', 'events'],
  });

  useEffect(() => {
    if (timerType.value === TimerTypeDto.SEPARATE) {
      form.setValue('timer', undefined);
    }
  }, [timerType.value, form]);

  const loadBalances = useMemo(
    () => debounce(accountsStore.accountBalances.fetch, 500),
    [accountsStore.accountBalances],
  );

  useEffect(() => {
    if (accountId) loadBalances(accountId);

    return () => {
      accountsStore.accountBalances.reset();
      resetField('currency');
    };
  }, [loadBalances, accountId, accountsStore.accountBalances, resetField]);

  const onEditEvent = () => {
    setStep(Step.EditEvents);
  };

  const onSaveEvents = (events: (CreateQuestStep & isInstallEvent)[]) => {
    setStep(Step.Create);
    setValue('events', events);
  };

  return (
    <FormProvider {...form}>
      {step === Step.Create && (
        <CreateModal
          quest={quest}
          countryOptions={countryOptions}
          onCreate={onCreate}
          onDelete={onDelete}
          onEditEvent={onEditEvent}
        />
      )}
      {step === Step.EditEvents && (
        <EditEvents
          isUniversal={timerType.value === TimerTypeDto.UNIVERSAL}
          isEdit={!!quest}
          timer={timer}
          onSave={onSaveEvents}
          events={questEvents || []}
          onBack={() => setStep(Step.Create)}
        />
      )}
    </FormProvider>
  );
});

export const useSidebarCreateQuest = ({
  quest,
  onCreate,
  onDelete,
  onClose,
}: Props) => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  const showSidebar = useCallback(() => {
    layoutStore.toggleSidebar(
      true,
      intl.formatMessage({
        id: 'sidebar.quest',
        defaultMessage: quest ? 'Edit quest info' : 'Create a quest',
      }),
      <SidebarQuestCreate
        quest={quest}
        onCreate={onCreate}
        onDelete={onDelete}
      />,
      onClose,
    );
  }, [quest, intl, layoutStore, onCreate, onDelete]);

  return {
    showCreateQuestSidebar: showSidebar,
  };
};
