import { QuestStatusDto, UserQuestStatusDto } from 'api/quests';
import classNames from 'classnames';
import React from 'react';
import { capitalizeString } from 'utils/strings';

import styles from './index.module.css';

type BadgeStatusProps = {
  status: QuestStatusDto | UserQuestStatusDto;
};

export const BadgeStatus = ({ status }: BadgeStatusProps) => {
  return (
    <div className={classNames(styles.container, styles[status.toLowerCase()])}>
      <span className={styles.status}>{capitalizeString(status)}</span>
    </div>
  );
};
