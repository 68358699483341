/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {
  AdminQuest,
  QuestStatusDto,
  QuestStep,
  PlatformTypeDto,
  TimerTypeDto,
} from 'api/quests';

export class AdminQuestsModel {
  constructor (dto: AdminQuest) {
    this.id = dto.id ?? '';
    this.accountId = dto.accountId ?? '';
    this.budget = dto.budget;
    this.reward = dto.reward;
    this.currency = dto.currency;
    this.name = dto.name;
    this.delay = dto.delay;
    this.status = dto.status;
    this.budgetBalance = dto.budgetBalance;
    this.questSteps = [...(dto.questSteps ?? [])].sort((a, b) => {
      return (a.position ?? 0) - (b.position ?? 0);
    });
    this.startedAt = dto.startedAt;
    this.expiredAt = dto.expiredAt;
    this.templateUrl = dto.templateUrl;
    this.activeUsers = dto.activeUsers;
    this.enrolledUsers = dto.enrolledUsers;
    this.active = dto.active;
    this.questId = dto.questId;
    this.claimedUsers = dto.claimedUsers;
    this.completedUsers = dto.completedUsers;
    this.description = dto.description;
    this.image = dto.image;
    this.platforms = dto.platforms;
    this.countries = dto.countries;
    this.timer = dto.timer;
    this.cpi = dto.cpi;
    this.timerType = dto.timerType;
    this.completedRate = dto.completedRate;
    this.totalRevenue = dto.totalRevenue;
  }

  id: string;
  questId?: string;
  accountId?: string;
  description?: string;
  timer?: number;
  completedRate?: number;
  totalRevenue?: number;
  cpi?: number;
  budget?: number;
  timerType?: TimerTypeDto;
  platforms?: PlatformTypeDto[];
  countries?: string[];
  image?: string;
  budgetBalance?: number;
  name?: string;
  currency?: string;
  reward?: number;
  enrolledUsers?: number;
  completedUsers?: number;
  activeUsers?: number;
  claimedUsers?: number;
  status?: QuestStatusDto;
  delay?: number;
  templateUrl?: string;
  expiredAt?: string;
  startedAt?: string;
  questSteps?: QuestStep[];
  requirement?: object;
  active?: boolean;
}
