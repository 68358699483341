import { observer } from 'mobx-react-lite';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import joi from 'joi';
import styles from './index.module.css';
import Button from 'components/core/button';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { CreateQuestStep } from 'api/quests';
import { useIntlValidation } from 'hooks/intl/validation';
import { joiResolver } from '@hookform/resolvers/joi';
import { FaPlus } from 'react-icons/fa6';
import { Event } from './Event';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  transformFromBackendTimer,
  transformToBackendTimer,
} from 'utils/dates';
import { CreateQuestForm, isInstallEvent } from '../index';

type Props = {
  isEdit: boolean;
  events: (CreateQuestStep & isInstallEvent)[];
  isUniversal: boolean;
  onSave: (events: CreateQuestStep[]) => void;
  onBack: () => void;
  timer?: number;
};

export type EventForm = {
  eventId?: string;
  isInstall?: boolean;
} & Omit<CreateQuestStep, 'events'>;

export type EventsForm = {
  events?: EventForm[];
};

export const EditEvents: FC<Props> = observer(
  ({ isEdit, events, onBack, onSave, isUniversal, timer }) => {
    const { layoutStore } = useBaseStores();
    const intl = useIntl();

    useEffect(() => {
      layoutStore.setSidebarTitle(
        intl.formatMessage({
          id: 'sidebar.quest.events',
          defaultMessage: 'Events',
        })
      );

      return () => {
        layoutStore.setSidebarTitle(
          intl.formatMessage({
            id: 'sidebar.quest',
            defaultMessage: isEdit ? 'Edit quest info' : 'Create a quest',
          })
        );
      };
    }, [layoutStore, isEdit, intl]);

    const { validationOptions } = useIntlValidation();

    const formScheme = useMemo(
      () =>
        joi.object<EventsForm>({
          events: joi.array().items(
            joi.object({
              id: joi.string().optional(),
              name: joi.string().empty('').required(),
              eventId: joi.string().empty('').required(),
              events: joi.array(),
              timer: joi.number().empty(''),
              exclusive: joi.boolean(),
              position: joi.number(),
              reward: joi
                .number()
                .min(0)
                .max(Number.MAX_SAFE_INTEGER)
                .empty(undefined)
                .empty(''),
              isInstall: joi.boolean(),
            })
          ),
        }),
      []
    );

    const getDefaultEvents = () => {
      const installEvent = {
        name: 'Install',
        eventId: 'install',
        exclusive: false,
        timer: undefined,
        reward: undefined,
        isInstall: true,
        position: 0,
      };

      if (events.length) {
        return [
          //installEvent,
          ...events.map(
            ({
              id,
              name,
              events,
              timer: t,
              position,
              exclusive,
              reward,
              isInstall,
            }) => ({
              id,
              //@ts-ignore
              eventId: events?.[0]?.event_id,
              name,
              timer: t || timer,
              position: position + 1,
              exclusive,
              reward,
              events: undefined,
              isInstall: isInstall,
            })
          ),
        ];
      }

      return [installEvent];
    };

    const form = useForm<EventsForm>({
      resolver: joiResolver(formScheme, validationOptions),
      mode: 'onChange',
      defaultValues: {
        events: getDefaultEvents(),
      },
    });

    const { control, handleSubmit } = form;

    const { move, fields, remove, append } = useFieldArray<EventsForm>({
      name: 'events',
      control,
    });

    const { getValues } = useFormContext<CreateQuestForm>();
    const questCurrency = getValues('currency');

    const onSubmit = ({ events }: EventsForm) => {
      if (!events) {
        onSave([]);
        return;
      }

      const transformedEvents = events
        /*.filter((event) => !event.isInstall)*/
        .map((event, index) => {
          const { timer, eventId, id, isInstall, ...rest } = event;

          let transformedTimer: number | undefined;
          if (timer !== 0) {
            const backendTimer = transformToBackendTimer(timer?.toString());
            transformedTimer =
              backendTimer !== undefined
                ? (transformFromBackendTimer(backendTimer).value as any)
                : undefined;
          }

          return {
            ...rest,
            timer: transformedTimer,
            id: id,
            events: [{ event_id: eventId }],
            position: index,
            isInstall: eventId === 'install',
          };
        });

      onSave(transformedEvents);
    };

    const onAddEvent = () => {
      append({
        id: undefined,
        name: '',
        reward: 0,
        exclusive: false,
        position: fields.length,
        timer: isUniversal ? timer : undefined,
        isInstall: false,
      });
    };

    const moveCard = useCallback(
      (dragIndex: number, hoverIndex: number) => {
        // Prevent moving Install event or moving other events before it
        if (dragIndex === 0 || hoverIndex === 0) return;
        move(dragIndex, hoverIndex);
      },
      [move]
    );

    return (
      <FormProvider {...form}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.wrapper}>
              <h4>Events</h4>
              <span className={styles.description}>In order of execution</span>
            </div>
            <Button
              className={styles.button}
              size='medium'
              variant='primary'
              onClick={onAddEvent}
            >
              {intl.formatMessage({
                id: 'sidebar.create_event.add_event',
                defaultMessage: 'Add event',
              })}
              <FaPlus />
            </Button>
          </div>
          <DndProvider backend={HTML5Backend}>
            <div className={styles.list}>
              {fields.map((event, i) => (
                <Event
                  isUniversal={isUniversal}
                  key={event.id}
                  index={i}
                  id={event.id}
                  event={event as EventForm}
                  moveCard={moveCard}
                  onDelete={() => remove(i)}
                  timer={timer}
                  questCurrency={questCurrency}
                />
              ))}
            </div>
          </DndProvider>
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.apply_btn}
            variant='secondary'
            onClick={onBack}
          >
            {intl.formatMessage({
              id: 'sidebar.quest_create.back',
              defaultMessage: 'Back',
            })}
          </Button>
          <Button
            className={styles.apply_btn}
            variant='primary'
            onClick={handleSubmit(onSubmit, (error) => console.log(error))}
          >
            {intl.formatMessage({
              id: 'sidebar.quest_create.save',
              defaultMessage: 'Save changes',
            })}
          </Button>
        </div>
      </FormProvider>
    );
  }
);
