/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import SearchField from 'components/core/search-field';

import { URL_SEARCH_PARAMS } from 'assets/config';
import { observer } from 'mobx-react-lite';
import { AdminListRequestModel } from 'models/request/AdminListRequestModel';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSetQueryParams } from 'hooks/collection-query-params';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import Button from 'components/core/button';
import { FaPlus } from 'react-icons/fa6';
import { useSidebarUserGroupCreate } from 'components/sidebars/sidebar-user-group-create';
import { NavigationTab } from '../navigation-tabs';
import { AdminGroupModel } from 'models/GroupModel';
import { useGroupsPageStore } from '../provider/GroupsStoreProvider';

type Props = {
  users: CollectionWithPages<AdminGroupModel, AdminListRequestModel>;
};

const GroupsFilter = observer(({ users }: Props) => {
  const intl = useIntl();
  const groupsPageStore = useGroupsPageStore();
  const { setFilterQueryParams } = useSetQueryParams();

  const onSearchChanged = useCallback(
    (val: string) => {
      setFilterQueryParams([
        { id: URL_SEARCH_PARAMS.page, value: '1' },
        { id: URL_SEARCH_PARAMS.search, value: val },
      ]);
    },
    [setFilterQueryParams]
  );

  const { showCreateUserGroupSidebar } = useSidebarUserGroupCreate({
    onSubmit: () => groupsPageStore.groups.refresh(),
  });

  return (
    <div className={styles.main}>
      <NavigationTab />
      <div className={styles.filters}>
        <SearchField
          className={styles.search_input}
          placeholder={intl.formatMessage({
            id: 'page.groups.search.placeholder',
            defaultMessage: 'Search by user groups',
          })}
          value={users.filter.search ?? ''}
          onChange={onSearchChanged}
        />

        <Button variant='primary' onClick={() => showCreateUserGroupSidebar()}>
          <span>
            {intl.formatMessage({
              id: 'page.groups.buttons.create_group',
              defaultMessage: 'To create a group',
            })}
          </span>

          <FaPlus />
        </Button>
      </div>
    </div>
  );
});

export default GroupsFilter;
