/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

import styles from './index.module.css';
import Button from 'components/core/button';
import FormSelect from 'components/form/form-select';

import {
  UpdateUserQuestStepStatus,
  UserQuestStep,
  UserQuestStepStatusDto,
} from 'api/quests';
import { useBaseStores } from 'providers/BaseStoresProvider';

type Props = {
  questSteps: UserQuestStep[];
  userQuestId: string;
  updateSteps: (steps: UpdateUserQuestStepStatus[]) => Promise<void>;
}

type FormFields = {
  steps: UserQuestStep[]
};

const ChangeStepsStatusModal: FC<Props> = ({
  questSteps,
  userQuestId,
  updateSteps,
}) => {
  const { formatMessage } = useIntl();
  const { layoutStore } = useBaseStores();

  const schema = useMemo(
    () => Joi.object({
      steps: Joi.array()
                .items(
                  Joi.object({
                    id: Joi.string(),
                    questStep: Joi.any(),
                    status: Joi.string()
                               .valid(...Object.values(UserQuestStepStatusDto))
                               .required(),
                    finishedAt: Joi.string(),
                    timerFinishedAt: Joi.string(),
                  }),
                )
                .required(),
    }),
    []);

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm<FormFields>({
    resolver: joiResolver(schema),
    defaultValues: { steps: questSteps },
  });

  const closeModal = () => {
    layoutStore.toggleModal(false);
  };

  const saveChanges = (values: FormFields) => {
    const changedSteps = values.steps.filter((_, index) =>
      dirtyFields.steps?.[index]?.status,
    );
    if (changedSteps.length === 0) return;

    const mappedSteps = changedSteps.map((step) => ({
      userQuestId: userQuestId,
      questStepId: step.questStep?.id,
      status: step.status,
    }));

    void updateSteps(mappedSteps as UpdateUserQuestStepStatus[]);
    layoutStore.toggleModal(false);
  };

  const statusOptions = Object.values(UserQuestStepStatusDto).map((item) => ({
    id: item,
    label: item,
    value: item,
  }));

  const questStepsElements = questSteps.map((step, index) => {
    const { questStep } = step;

    return (
      <div key={index} className={styles.step}>
        <div>{questStep?.name ?? questStep?.id}</div>
        <div className={styles['step-select']}>
          <Controller
            control={control}
            name={`steps.${index}.status` as const}
            render={({ field: { value, onChange } }) => (
              <FormSelect
                variant="borderless"
                options={statusOptions}
                value={statusOptions.find((item) => item.value === value)}
                onChange={(value) => onChange(value?.value as string)}
              />
            )}
          />
        </div>
      </div>
    );
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.steps}>
        {questStepsElements}
      </div>

      <div className={styles.btns}>
        <Button
          className={styles.btn}
          variant="secondary"
          onClick={closeModal}
        >
          {formatMessage({
            id: 'table.quests.col.actions.change_steps_status.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>

        <Button
          className={styles.btn}
          variant="primary"
          onClick={handleSubmit(saveChanges)}
        >
          {formatMessage({
            id: 'table.quests.col.actions.change_steps_status.save',
            defaultMessage: 'Save changes',
          })}
        </Button>
      </div>
    </div>
  );
};

export const useChangeQuestStepsStatus = (updateSteps: any) => {
  const { formatMessage, locale } = useIntl();
  const { layoutStore } = useBaseStores();

  const showStepsStatusModal = useCallback(
    (questSteps: UserQuestStep[], userQuestId: string) => {
      layoutStore.toggleModal(
        true,
        formatMessage({
          id: 'table.quests.col.actions.change_steps_status.title',
          defaultMessage: 'Quest steps',
        }),
        <ChangeStepsStatusModal
          questSteps={questSteps}
          userQuestId={userQuestId}
          updateSteps={updateSteps}
        />,
      );
    },
    [locale, layoutStore],
  );

  return {
    showStepsStatusModal,
  };
};
