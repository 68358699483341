/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import Button from 'components/core/button';
import { Switch, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useCallback, useState } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useSetQueryParams } from 'hooks/collection-query-params';
import { CreateQuest, PlatformTypeDto, QuestStatusDto, TimerTypeDto } from 'api/quests';
import DateTimePicker, { DatePickerSize } from 'components/core/datepicker';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import FormField from 'components/form/form-field';
import FormSelect from 'components/form/form-select';
import { AdminQuestsModel } from 'models/AdminQuestModel';
import { Events } from '../Events';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDeleteQuest } from '../DeleteModal';
import { BadgeStatus } from 'entities/quest';
import { PatternFormat } from 'react-number-format';
import InputField from 'components/core/input-field';
import FormListMultiSelect from 'components/form/form-list-multi-select';
import FormListMultiSelectDropdown
  from 'components/form/form-list-multi-select-dropdown';
import FormListSingleSelect from 'components/form/form-list-single-select';
import { CreateQuestForm } from '..';
import { transformToBackendTimer } from 'utils/dates';
import { Banner } from './Banner';
import { useTimerTypeSynchronization } from './useTimerTypeSynchronization';
import { SelectItemModel } from 'models/form/SelectItemModel';

type Props = {
  quest?: AdminQuestsModel;
  countryOptions: SelectItemModel<string>[];
  onCreate: (quest: CreateQuest) => Promise<void>;
  onDelete?: (questId: string) => void;
  onEditEvent: () => void;
};

export const CreateModal = observer(
  ({ quest, countryOptions, onCreate, onDelete, onEditEvent }: Props) => {
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(false);

    useTimerTypeSynchronization();

    const { layoutStore, accountsStore, currencyStore } = useBaseStores();
    const { setFilterQueryParams } = useSetQueryParams();

    const accounts = accountsStore.accounts.map((id) => ({
      id: id,
      value: id,
      label: id,
    }));

    const timersTypes = [
      {
        id: TimerTypeDto.UNIVERSAL,
        label: intl.formatMessage({
          id: 'sidebar.create_quest.universal',
          defaultMessage: 'Universal timer',
        }),
        value: TimerTypeDto.UNIVERSAL,
      },
      {
        id: TimerTypeDto.SEPARATE,
        label: intl.formatMessage({
          id: 'sidebar.create_quest.separate',
          defaultMessage: 'Separate timer',
        }),
        value: TimerTypeDto.SEPARATE,
      },
    ];

    const {
      handleSubmit,
      control,
      reset,
      watch,
      setError,
      clearErrors,
      formState,
      setValue
    } = useFormContext<CreateQuestForm>();
    const { errors, isValid } = formState;

    const REQUIRED_TEMPLATE_URL_PARAMS = ['pid', 'af_siteid', 'c', 'af_sub1', 'af_sub2'];
    const REQUIRED_TEMPLATE_URL_PLACEHOLDERS = {
      af_sub1: '{userid}',
      af_sub2: '{questid}',
    } as const;
    const REQUIRED_TEMPLATE_URL_COST_HASH = { af_enc_data: '{cost_hash}' } as const;

    const [isCostHashAdded, setCostHashStatus] = useState(false);
    const [isLinkValidating, setLinkValidationStatus] = useState(false);

    const handleTemplateUrlBlur = useCallback(() => {
      const templateUrl = watch('templateUrl');
      if (!templateUrl) return;

      setLinkValidationStatus(true);

      try {
        const url = new URL(templateUrl);
        const queryParams = new URLSearchParams(url.search);

        const missingParams = REQUIRED_TEMPLATE_URL_PARAMS.filter(
          (param) => !queryParams.has(param)
        );
        if (missingParams.length > 0) {
          setError('templateUrl', {
            type: 'manual',
            message: intl.formatMessage({
              id: 'sidebar.create_quest.link.validation.params',
              defaultMessage: 'The link must contain the required parameters: {missingParams}',
            }, { missingParams: missingParams.join(', ') },),
          });
          setCostHashStatus(false);
          return;
        }

        const missingPlaceholders = Object.keys(REQUIRED_TEMPLATE_URL_PLACEHOLDERS).filter((param) => {
          const paramValue = queryParams.get(param);
          return paramValue !== REQUIRED_TEMPLATE_URL_PLACEHOLDERS[param as keyof typeof REQUIRED_TEMPLATE_URL_PLACEHOLDERS];
        });
        if (missingPlaceholders.length > 0) {
          setError('templateUrl', {
            type: 'manual',
            message: intl.formatMessage({
              id: 'sidebar.create_quest.link.validation.placeholders',
              defaultMessage: 'The parameters \"af_sub1\" and \"af_sub2\" must contain \'{userid} and {questid}\'',
            }),
          });
          setCostHashStatus(false);
          return;
        }

        const [costHashKey, costHashValue] = Object.entries(REQUIRED_TEMPLATE_URL_COST_HASH)[0];
        if (!queryParams.has(costHashKey) || queryParams.get(costHashKey) !== costHashValue) {
          queryParams.set(costHashKey, costHashValue);
          url.search = queryParams.toString();
          const updatedUrl = decodeURI(url.toString());

          setValue('templateUrl', updatedUrl, { shouldValidate: true });
          setCostHashStatus(true);
        } else {
          setCostHashStatus(false);
        }

        clearErrors('templateUrl');
      } catch {
        setError('templateUrl', {
          type: 'manual',
          message: intl.formatMessage({
            id: 'sidebar.create_quest.link.validation.url',
            defaultMessage: 'The link must be a valid URL',
          }),
        });
        setCostHashStatus(false);
      }
      finally {
        setLinkValidationStatus(false);
      }
    }, [setError, setValue, clearErrors, intl]);

    const onSubmit = useCallback(
      async ({
        timer,
        events,
        timerType,
        platforms,
        countries,
        image,
        ...values
      }: CreateQuestForm) => {
        handleTemplateUrlBlur();
        const hasTemplateUrlErrors = Boolean(errors.templateUrl);
        if (hasTemplateUrlErrors) return;

        setIsLoading(true);

        const reward = events.reduce(
          (acc, { reward }) => Number(reward || 0) + acc,
          0,
        );

        const handleFileToBase64 = (file: any) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result); // Base64 строка
            reader.onerror = (error) => reject(error);
          });
        };
        const base64 = image?.originFileObj
          ? await handleFileToBase64(image.originFileObj)
          : undefined;
        try {
          await onCreate({
            ...values,
            steps: events.map((item) => ({
              ...item,
              reward: Number(item.reward || 0),
              timer: transformToBackendTimer(
                item?.timer === 0 ? undefined : item?.timer?.toString(),
              ),
            })),
            startedAt: values.startedAt.toISOString(),
            expiredAt: values.expiredAt.toISOString(),
            timer:
              timer === 0 || timer?.toString() === '00000000'
                ? undefined
                : transformToBackendTimer(timer?.toString()),
            timerType: timerType.id as TimerTypeDto,
            platforms: platforms.map(({ id }) => id) as PlatformTypeDto[],
            countries: countries ? countries.map(({ id }) => id) : [],
            reward,
            budget: values.budget ?? undefined,
            image: base64
              ? {
                image: base64 as string,
                name: image.name,
                type: image.type as any,
              }
              : undefined,
          });
          setIsLoading(false);
          reset();
          layoutStore.toggleSidebar(false);
        } catch (error) {
          console.error(error);
        }
        setIsLoading(false);
      },
      [layoutStore, setFilterQueryParams, onCreate, reset, errors, handleTemplateUrlBlur],
    );

    const handleDelete = async () => {
      if (!quest) return;
      onDelete?.(quest.id);
      reset();
      layoutStore.toggleSidebar(false);
    };

    const { showDeleteQuestModal } = useDeleteQuest();

    const [startedAt, expiredAt, timerType, events] = useWatch<CreateQuestForm>(
      {
        control,
        name: ['startedAt', 'expiredAt', 'timerType', 'events'],
      },
    );

    const totalReward = Number(
      events
      .reduce(
        (acc: number, { reward }: CreateQuestForm) =>
          acc + Number(reward || 0),
        0,
      )
      .toFixed(2),
    );

    const selectOptions = currencyStore.currencies.map((item) => ({
      id: item.id,
      value: item.id,
      label: item.id,
      icon: item?.iconUrl,
    }));

    return (
      <>
        <div className={styles['status-container']}>
          <Controller
            control={control}
            name="active"
            render={({ field: { value, onChange } }) => (
              <Tooltip
                title={intl.formatMessage({
                  id: 'sidebar.create_quest.quest_status_tooltip',
                  defaultMessage: value ? 'Quest is active' : 'Quest is paused',
                })}
              >
                <Switch
                  value={value}
                  onChange={(v) => onChange(v)}
                  size="small"
                />
              </Tooltip>
            )}
          />
          {quest && <BadgeStatus status={quest.status as QuestStatusDto} />}
        </div>

        <div className={styles.container}>
          <Controller
            control={control}
            name="name"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.create_quest.name',
                  defaultMessage: 'Name',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.create_quest.name.placeholder',
                  defaultMessage: 'Quest name that the user will see',
                })}
                variant="secondary"
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="accountId"
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormSelect
                  label={intl.formatMessage({
                    id: 'sidebar.create_quest.account_id',
                    defaultMessage: 'Account ID',
                  })}
                  variant="borderless"
                  options={accounts}
                  placeholder={intl.formatMessage({
                    id: 'sidebar.create_quest.account_id',
                    defaultMessage: 'Account ID',
                  })}
                  onChange={(value) => onChange(value?.value as string)}
                  value={accounts.find((item) => item.value === value)}
                  error={error?.message}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="currency"
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormSelect
                  label={intl.formatMessage({
                    id: 'sidebar.create_quest.currency',
                    defaultMessage: 'Currency',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'sidebar.create_quest.currency.placeholder',
                    defaultMessage: 'Currency',
                  })}
                  variant="borderless"
                  options={selectOptions}
                  value={selectOptions.find((item) => item.value === value)}
                  onChange={(value) => onChange(value?.value as string)}
                  error={error?.message}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="description"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.create_quest.description',
                  defaultMessage: 'Description',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.create_quest.description.placeholder',
                  defaultMessage:
                    'Description of the quest that the user will see',
                })}
                type="text_area"
                variant="secondary"
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />

          <Banner image={quest?.image} />

          <div className={styles.two_cols}>
            <div className={styles.col}>
              <h5>
                {intl.formatMessage({
                  id: 'sidebar.create_quest.start',
                  defaultMessage: 'Start',
                })}
              </h5>
              <Controller
                control={control}
                name="startedAt"
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <DateTimePicker
                    allowClear={false}
                    placeholder="Date and time"
                    dateFormat={{
                      format: 'YYYY-MM-DD HH:mm:ss',
                      type: 'mask',
                    }}
                    size={DatePickerSize.Large}
                    onChange={onChange}
                    value={value ? new Date(value) : undefined}
                    maxDate={expiredAt}
                    error={error?.message}
                  />
                )}
              />
            </div>
            <div className={styles.col}>
              <h5>
                {intl.formatMessage({
                  id: 'sidebar.create_quest.end',
                  defaultMessage: 'End',
                })}
              </h5>
              <Controller
                control={control}
                name="expiredAt"
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <DateTimePicker
                    allowClear={false}
                    placeholder="Date and time"
                    dateFormat={{
                      format: 'YYYY-MM-DD HH:mm:ss',
                      type: 'mask',
                    }}
                    size={DatePickerSize.Large}
                    onChange={onChange}
                    value={value ? new Date(value) : undefined}
                    minDate={startedAt}
                    error={error?.message}
                  />
                )}
              />
            </div>
          </div>

          <div className={styles.col}>
            <div className={styles['timer-container']}>
              <h5>
                {intl.formatMessage({
                  id: 'sidebar.create_quest.quest_timer',
                  defaultMessage: 'Quest timer',
                })}
              </h5>
              <Controller
                control={control}
                name="timerType"
                render={({ field: { value, onChange } }) => (
                  <FormListSingleSelect
                    title=""
                    className={styles.timer}
                    options={timersTypes}
                    selected={value}
                    onSelect={onChange}
                  />
                )}
              />
            </div>

            <Controller
              control={control}
              name="timer"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <PatternFormat
                  disabled={timerType.value === TimerTypeDto.SEPARATE}
                  customInput={InputField}
                  format="##:##:##:##"
                  /* allowEmptyFormatting*/
                  placeholder="DD:HH:MM:SS"
                  mask={['D', 'D', 'H', 'H', 'M', 'M', 'S', 'S']}
                  isFormatted
                  variant="secondary"
                  value={value || ''}
                  onValueChange={(e) => {
                    onChange(e.value);
                  }}
                  onBlur={() => {
                    if (!value) return;

                    const newValue = Array.from({ length: 8 })
                      .map((_, index) => value.toString()[index] || '0')
                      .join('');

                    onChange(newValue);
                  }}
                  error={error?.message}
                />
              )}
            />
          </div>

          <Controller
            control={control}
            name="platforms"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <div className={styles.col}>
                <FormListMultiSelect
                  title={intl.formatMessage({
                    id: 'sidebar.create_quest.platforms',
                    defaultMessage: 'Platforms',
                  })}
                  options={[
                    {
                      id: PlatformTypeDto.ANDROID,
                      label: 'Android',
                      value: PlatformTypeDto.ANDROID,
                    },
                    {
                      id: PlatformTypeDto.WEB,
                      label: 'Web',
                      value: PlatformTypeDto.WEB,
                    },

                    {
                      id: PlatformTypeDto.IOS,
                      label: 'IOS',
                      value: PlatformTypeDto.IOS,
                    },
                  ]}
                  selected={value}
                  onSelect={onChange}
                  collapse
                />
                {error?.message && (
                  <span className={styles.error}>{error?.message}</span>
                )}
              </div>
            )}
          />

          <Controller
            control={control}
            name="countries"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <div className={styles.col}>
                <FormListMultiSelectDropdown
                  title={intl.formatMessage({
                    id: 'sidebar.create_quest.countries',
                    defaultMessage: 'Countries',
                  })}
                  entitiesName={intl.formatMessage({
                    id: 'sidebar.create_quest.countries.dropdown_entities',
                    defaultMessage: 'countries',
                  })}
                  entitiesCount={intl.formatMessage({
                    id: 'sidebar.create_quest.countries.count_entities',
                    defaultMessage: 'countries selected. Click on the text field to see and select countries.',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'sidebar.create_quest.countries.placeholder',
                    defaultMessage: 'Search by country',
                  })}
                  options={countryOptions}
                  selected={value}
                  onSelect={onChange}
                />
                {error?.message && (
                  <span className={styles.error}>{error?.message}</span>
                )}
              </div>
            )}
          />

          <DndProvider backend={HTML5Backend}>
            <Events onEditEvent={onEditEvent} />
          </DndProvider>

          <Controller
            control={control}
            name="delay"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.create_quest.delay',
                  defaultMessage: 'Delay between events',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.create_quest.delay_between_events.placeholder',
                  defaultMessage: '60s',
                })}
                variant="secondary"
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />

          <div className={styles.three_cols}>
            <Controller
              control={control}
              name="reward"
              render={({ fieldState: { error } }) => (
                <FormField
                  disabled
                  label={intl.formatMessage({
                    id: 'sidebar.create_quest.reward',
                    defaultMessage: 'Reward',
                  })}
                  placeholder="60 USDT"
                  variant="secondary"
                  value={totalReward}
                />
              )}
            />

            <Controller
              control={control}
              name="budget"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <FormField
                  label={intl.formatMessage({
                    id: 'sidebar.create_quest.budget',
                    defaultMessage: 'Budget',
                  })}
                  tooltip="You can leave this field blank if you don’t want to set a budget limit."
                  placeholder="$100"
                  variant="secondary"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="cpi"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <FormField
                  label={intl.formatMessage({
                    id: 'sidebar.create_quest.cpi',
                    defaultMessage: 'CPI',
                  })}
                  placeholder="$10"
                  variant="secondary"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>

          <Controller
            control={control}
            name="templateUrl"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.create_quest.link',
                  defaultMessage: 'Quest link',
                })}
                placeholder="https://www.test.com/?quest={userid}/{questid}&af_enc_data={cost_hash}&af_click_lookback=7d"
                variant="secondary"
                value={value}
                onChange={(e) => {
                  setLinkValidationStatus(true);
                  onChange(e);
                }}
                error={error?.message}
                tooltip={intl.formatMessage({
                  id: 'sidebar.create_quest.link.tooltip',
                  defaultMessage:
                    'The link must include a valid URL with userid, questid, and cost_hash or cost_value placeholders',
                })}
                onBlur={() => handleTemplateUrlBlur()}
              />
            )}
          />
          {isCostHashAdded && (
            <span className={styles['cost-hash-hint']}>
              {intl.formatMessage({
                id: 'sidebar.create_quest.link.validation.added',
                defaultMessage: 'The parameter af_enc_data=\'{cost_hash}\' was added to link',
              })}
            </span>
          )}
        </div>

        {quest ? (
          <div className={styles.buttons}>
            <Button
              className={styles.apply_btn}
              variant="negative"
              onClick={() => showDeleteQuestModal(handleDelete)}
            >
              {intl.formatMessage({
                id: 'sidebar.quest_create.delete',
                defaultMessage: 'Delete quest',
              })}
            </Button>
            <Button
              className={styles.apply_btn}
              variant="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading || !isValid || isLinkValidating}
            >
              {intl.formatMessage({
                id: 'sidebar.quest_create.save',
                defaultMessage: 'Save changes',
              })}
            </Button>
          </div>
        ) : (
          <Button
            className={styles.apply_btn}
            variant="primary"
            disabled={isLoading || !isValid || isLinkValidating}
            onClick={handleSubmit(onSubmit)}
          >
            {intl.formatMessage({
              id: 'sidebar.quest_create.create',
              defaultMessage: 'Create',
            })}
          </Button>
        )}
      </>
    );
  },
);
