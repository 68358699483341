/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import DashboardSection from '../section';

import { observer } from 'mobx-react-lite';
import { useDashboardPageStore } from '../provider/DashboardPageStoreProvider';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';

const DashboardUserCounters = observer(() => {
  const intl = useIntl();
  const store = useDashboardPageStore();

  const counters = useMemo(
    () =>
      store.stats
        ? [
            {
              title: intl.formatMessage({
                id: 'page.dashboard.users_counters.total_users',
                defaultMessage: 'Total users',
              }),
              value: store.stats.totalUsers,
            },
            {
              title: intl.formatMessage({
                id: 'page.dashboard.users_counters.active_users',
                defaultMessage: 'Active users',
              }),
              value: store.stats.activeUsers,
            },
            {
              title: intl.formatMessage({
                id: 'page.dashboard.users_counters.pending_sumbsub',
                defaultMessage: 'Pending sumsub',
              }),
              value: store.stats.pendingSumsub,
            },
            {
              title: intl.formatMessage({
                id: 'page.dashboard.users_counters.pending_approval',
                defaultMessage: 'Pending approval',
              }),
              value: store.stats.pendingApproval,
            },
          ]
        : null,
    [intl, store.stats]
  );

  if (!counters) {
    return null;
  }

  return (
    <DashboardSection
      title={intl.formatMessage({
        id: 'page.dashboard.user_counters',
        defaultMessage: 'Users',
      })}
    >
      <div className={styles.counters_grid}>
        {counters.map((x) => (
          <div key={x.title} className={styles.counter}>
            <span>{x.title}</span>
            <h4>{x.value}</h4>
          </div>
        ))}
      </div>
    </DashboardSection>
  );
});

export default DashboardUserCounters;
