/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';

import { useIntl } from 'react-intl';
import { FaChevronLeft } from 'react-icons/fa6';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

type Props = {
  fallbackUrl: string;
  alwaysFallback?: boolean;
}

const BackButton = (props: Props) => {
  const { fallbackUrl, alwaysFallback } = props;
  const intl = useIntl();

  const navigate = useNavigate();
  const location = useLocation();

  const hasPreviousLocation = location.key !== 'default';

  const navigateBack = useCallback(() => {
    if (alwaysFallback) {
      navigate({ pathname: fallbackUrl });
      return;
    }

    if (hasPreviousLocation) {
      navigate(-1);
      return;
    }

    navigate({ pathname: fallbackUrl });
  }, [fallbackUrl, hasPreviousLocation, navigate, alwaysFallback]);

  return (
    <button className={styles.main} onClick={navigateBack}>
      <div>
        <FaChevronLeft />
      </div>
      <span>
        {intl.formatMessage({ id: 'button.back', defaultMessage: 'Back' })}
      </span>
    </button>
  );
};

export default BackButton;
