import { TableProps } from 'antd';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';

import CopyButton from 'components/core/copy-button';
import Table from 'components/core/table';
import { capitalizeString, cutId } from 'utils/strings';
import styles from './index.module.css';
import { UserQuestsModel } from 'models/UserQuestModel';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import {
  calculateAge,
  DATE_FORMAT,
  formattedDate,
  getRemainingDate,
} from 'utils/dates';
import { BadgeStatus } from 'entities/quest';
import { getSexLabel } from 'utils/gender';

type UserProps = {
  collection: CollectionWithPages<
    UserQuestsModel,
    { page?: number; size?: number }
  >;
};

export const Users = observer(({ collection }: UserProps) => {
  const intl = useIntl();

  const columns: TableProps<UserQuestsModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.quests.col.userId',
        defaultMessage: 'ID',
      }),
      dataIndex: 'userId',
      key: 'userId',
      render: (userId) => (
        <div className='flex items-center gap-2'>
          <span>{cutId(userId)}</span>
          <CopyButton text={userId} />
        </div>
      ),
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.quests.col.email',
        defaultMessage: 'Email',
      }),
      dataIndex: 'userEmail',
      key: 'userEmail',
      render: (userEmail) => <span>{userEmail}</span>,
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.quests.col.age',
        defaultMessage: 'Age',
      }),
      dataIndex: 'userAge',
      key: 'userAge',
      render: (userAge) => <span>{calculateAge(userAge)}</span>,
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.quests.col.gender',
        defaultMessage: 'Gender',
      }),
      dataIndex: 'userGender',
      key: 'userGender',
      render: (userGender) => <span>{getSexLabel(userGender)}</span>,
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.quests.col.platforms',
        defaultMessage: 'Platforms',
      }),
      dataIndex: 'platforms',
      key: 'platforms',
      render: (platforms) => (
        <span>
          {platforms
            .map((platform: string) => capitalizeString(platform))
            .join(', ')}
        </span>
      ),
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.quests.col.start',
        defaultMessage: 'Start',
      }),
      dataIndex: 'startedAt',
      key: 'startedAt',
      render: (startedAt) => (
        <span>{formattedDate(startedAt, DATE_FORMAT)}</span>
      ),
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.quests.col.finish',
        defaultMessage: 'Finish',
      }),
      dataIndex: 'finishedAt',
      key: 'finishedAt',
      render: (finishedAt) => (
        <span>{formattedDate(finishedAt, DATE_FORMAT)}</span>
      ),
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.quests.col.duration',
        defaultMessage: 'Duration',
      }),
      dataIndex: 'duration',
      key: 'duration',
      render: (duration) => <span>{getRemainingDate(duration)}</span>,
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.subscriptions.col.status',
        defaultMessage: 'Status',
      }),
      dataIndex: 'status',
      key: 'status',
      render: (status) => <BadgeStatus status={status} />,
      width: 30,
    },
  ];

  return (
    <div className={styles.main}>
      <h3>
        {intl.formatMessage({
          id: 'page.quest.users',
          defaultMessage: 'Users',
        })}
      </h3>
      <Table<UserQuestsModel, { page?: number; size?: number }>
        collection={collection}
        columns={columns}
      />
    </div>
  );
});
