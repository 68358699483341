/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { WalletApi } from 'api';
import { Balance } from 'api/wallet';
import { makeAutoObservable, runInAction } from 'mobx';

export class AccountsStore {
  constructor(private walletApi: WalletApi) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public loading: boolean = false;
  public accounts: string[] = [];

  private getAccounts = async () => {
    runInAction(() => {
      this.loading = true;
    });

    try {
      const result = await this.walletApi.admin.adminListAccount({
        sort: 'createdAt,id',
        size: 10000,
      });

      runInAction(() => {
        this.accounts = result.data.data.map(({ accountId }) => accountId);
      });
    } catch (e) {
      console.error(e);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  public accountBalances = {
    error: '',
    loading: false,
    data: [] as Balance[],
    reset: () => {
      runInAction(() => {
        this.accountBalances.data = [];
      });
    },
    fetch: async (accountId: string) => {
      if (!accountId) {
        runInAction(() => {
          this.accountBalances.data = [];
        });
        return;
      }
      try {
        runInAction(() => {
          this.accountBalances.loading = true;
        });
        const result = await this.walletApi.admin.adminGetAccountBalance(
          accountId
        );
        runInAction(() => {
          this.accountBalances.loading = true;
          this.accountBalances.data = result.data;
        });
      } catch (error) {
        runInAction(() => {
          this.accountBalances.loading = false;
        });
      }
    },
  };

  async init() {
    this.getAccounts();
  }
}
