/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import InputField, { InputFieldProps } from '../input-field';

import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { debounce } from 'lodash';

type Props = {
  value: string;
  onChange: (val: string) => void;
  onClick?: (e: MouseEvent<HTMLInputElement>) => void;
  placeholder?: string;
  variant?: InputFieldProps['variant'];
  className?: string;
};

const DEBOUNCE_MS = 500;

const SearchField = (props: Props) => {
  const { value, onChange, onClick, placeholder, variant, className } = props;

  const [inputVal, setInputVal] = useState(value);

  const debouncedChange = useMemo(
    () => debounce(onChange, DEBOUNCE_MS),
    [onChange],
  );

  const onInputValChanged = useCallback(
    (val: string) => {
      setInputVal(val);
      debouncedChange(val);
    },
    [debouncedChange],
  );

  useEffect(() => {
    setInputVal(value);
  }, [value]);

  return (
    <InputField
      icon={<FaMagnifyingGlass />}
      value={inputVal}
      onChange={onInputValChanged}
      onClick={onClick}
      placeholder={placeholder}
      variant={variant}
      className={className}
    />
  );
};

export default SearchField;
