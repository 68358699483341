/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {
  AcquiringApi,
  AuthApi,
  CardHolderApi,
  LoansApi,
  QuestsApi,
  WalletApi,
} from 'api';
import { URL_SEARCH_PARAMS } from 'assets/config';
import { makeAutoObservable, runInAction } from 'mobx';
import { AccountUserModel } from 'models/AccountUserModel';
import { DepositModel } from 'models/DepositModel';
import { KycVerificationModel } from 'models/KycVerificationModel';
import {
  KybVerificationFileModel,
  KybVerificationModel,
} from 'models/KybVerificationModel';
import { LoanModel } from 'models/LoanModel';
import { TransactionAccountModel } from 'models/TransactionsModel';
import { UserModel } from 'models/UserModel';
import { UserSessionModel } from 'models/UserSessionModel';
import { WithdrawalModel } from 'models/WithdrawalModel';
import { CardsModel } from 'models/CardsModel';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import axios, { AxiosError } from 'axios';
import { BaseInvoiceModel } from 'models/InvoicesModel';
import { SubscriptionsUserModel } from 'models/SubscriptionsUserModel';
import {
  ApiErrorResponse,
  CompanyInfo,
  Individual,
  UserStatus,
  VerificationType,
} from 'api/auth';
import { NotificationStore } from 'stores/NotificationStore';
import { SubscriptionsDetailsModel } from 'models/SubscriptionsDetailModel';
import { CurrencyStore } from 'stores/CurrencyStore';
import BigNumber from 'bignumber.js';
import { AdminGroupModel } from 'models/GroupModel';
import { UserEventsQuestsModel, UserQuestsModel } from 'models/UserQuestModel';
import {
  UserRegistrationLocationModel,
} from 'models/UserRegistrationLocationModel';
import { UpdateUserQuestStepStatus, UserQuestStep } from 'api/quests';

type SyncKybStatus = 'processing' | 'synced' | null;

export class UserDetailsPageStore {
  constructor (
    private authApi: AuthApi,
    private walletApi: WalletApi,
    private loansApi: LoansApi,
    private cardHolderApi: CardHolderApi,
    private acquiringApi: AcquiringApi,
    private notificationStore: NotificationStore,
    private currencyStore: CurrencyStore,
    private questsApi: QuestsApi,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _partnerId: number = 0;
  private _details: UserModel | null = null;
  private _registrationLocation: UserRegistrationLocationModel | null = null;
  private _syncKybStatus: SyncKybStatus = null;
  private _syncKybData: {} | undefined = undefined;
  private _error: string | null = null;
  private _kybVerifications: KybVerificationModel | null = null;
  private _questSteps: UserQuestStep[] = [];

  private _sessions = new CollectionWithPages<UserSessionModel, {}>({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async () => {
      const result = await this.authApi.admin.adminGetUserSessions(
        this._details?.id ?? '',
      );

      const items = result.data.data.map((x) => new UserSessionModel(x));

      return {
        items: items,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },
    itemsPerPage: 12,
  });

  private _kybFilesCollec = new CollectionWithPages<
    KybVerificationFileModel,
    {}
  >({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async () => {
      let kybFiles: KybVerificationFileModel[][] = [];

      if (this._kybVerifications) {
        kybFiles = await Promise.all(
          this._kybVerifications.verifications.map(async (_) => {
            const filesList =
              await this.authApi.admin.adminGetVerificationDocuments(_);

            return filesList.data?.length > 0
              ? filesList.data.map(
                (fileData) => new KybVerificationFileModel(fileData, _),
              )
              : [];
          }),
        );
      }

      const flattedArr = kybFiles.length > 0 ? kybFiles.flat(1) : [];

      return {
        items: flattedArr,
        totalItems: flattedArr.length,
        totalPages: 1,
      };
    },
    itemsPerPage: 12,
  });

  private _kycVerifications = new CollectionWithPages<KycVerificationModel, {}>(
    {
      pageQueryParamId: URL_SEARCH_PARAMS.page,
      fetchFn: async () => {
        const result = await this.authApi.admin.adminGetVerifications({
          userId: this._details?.id ?? '',
        });

        const items = result.data.map((x) => new KycVerificationModel(x));

        return {
          items: items,
          totalItems: items.length,
          totalPages: 1,
        };
      },
      itemsPerPage: 12,
    },
  );

  private _accounts = new CollectionWithPages<AccountUserModel, {}>({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async () => {
      const result = await this.walletApi.admin.adminListAccountByUser(
        {
          userId: this.details?.id ?? '',
        },
        { headers: { PartnerId: this._partnerId } },
      );

      const items = result.data.map((x) => new AccountUserModel(x));

      return {
        items: items,
        totalItems: items.length,
        totalPages: 1,
      };
    },
    itemsPerPage: 12,
  });

  private _loans = new CollectionWithPages<LoanModel, {}>({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async () => {
      const result = await this.loansApi.admin.adminGetLoans({
        userId: this._details?.id ?? '',
      });

      const items = result.data.data.map((x) => new LoanModel(x));

      return {
        items: items,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },
    itemsPerPage: 12,
  });

  private _withdrawals = new CollectionWithPages<WithdrawalModel, {}>({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async () => {
      const result = await this.walletApi.admin.adminListWithdraw({
        userId: this._details?.id ?? '',
      });

      const items = result.data.data.map((x) => new WithdrawalModel(x));

      return {
        items: items,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },
    itemsPerPage: 12,
  });

  private _quests = new CollectionWithPages<
    UserEventsQuestsModel,
    { page?: number; size?: number }
  >({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async (params) => {
      const result =
        await this.questsApi.adminQuest.getUserQuestsByUserIdAndStatus({
          ...params,
          userId: this.details?.id!,
        });

      const items = result.data.data.map((x) => {
        // Calculate total steps
        const total_steps = x.steps.length;

        // Calculate executed steps (steps with status CLAIMED or COMPLETED)
        const executed_steps = x.steps.filter(
          (step) => step.status === 'CLAIMED' || step.status === 'COMPLETED',
        ).length;

        // Create a new UserQuestsModel with additional properties
        return new UserQuestsModel({
          ...x,
          total_steps,
          executed_steps,
        });
      });

      /*items.forEach((item, index) => {
        console.log(`Item ${index + 1}:`, item.executed_steps);
        console.log(`Item ${index + 1}:`, item.total_steps);
      });*/

      return {
        items: items,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },
    itemsPerPage: 12,
  });

  private _deposits = new CollectionWithPages<DepositModel, {}>({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async () => {
      const result = await this.walletApi.admin.adminListDeposit({
        userId: this._details?.id ?? '',
      });

      const items = result.data.data.map((x) => new DepositModel(x));

      return {
        items: items,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },
    itemsPerPage: 12,
  });

  private _cards = new CollectionWithPages<CardsModel, {}>({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async () => {
      const result = await this.cardHolderApi.adminCards.adminListCards({
        userId: this._details?.id ?? '',
      });

      const items = result.data.data.map((x) => new CardsModel(x));

      return {
        items: items,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },
    itemsPerPage: 12,
  });

  private _invoices = new CollectionWithPages<BaseInvoiceModel, {}>({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async () => {
      const result = await this.acquiringApi.adminInvoices.adminGetInvoices({
        userId: this._details?.id ?? '',
      });

      const items = result.data.data.map((x) => new BaseInvoiceModel(x));

      return {
        items: items,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },
    itemsPerPage: 12,
  });

  private _subscriptions = new CollectionWithPages<
    SubscriptionsUserModel,
    { page?: number; size?: number }
  >({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async (params) => {
      const result =
        await this.authApi.merchantAdminSubscription.adminGetSubscriptions({
          ...params,
          userId: this._details?.id ?? '',
        });

      const items = result.data.data.map((x) => new SubscriptionsUserModel(x));

      return {
        items: items,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },
    itemsPerPage: 12,
  });

  private _transactions = new CollectionWithPages<
    TransactionAccountModel,
    { page?: number; size?: number }
  >({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async (params) => {
      const result = await this.walletApi.admin.adminGetBalanceLog(
        { ...params, userId: this.details?.id },
        {
          headers: { PartnerId: this._partnerId },
        },
      );
      const items = result.data.data.map((x) => new TransactionAccountModel(x));

      return {
        items: items,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },
    itemsPerPage: 12,
  });

  private _subscriptionDetails = {
    items: [] as SubscriptionsDetailsModel[],

    fetchFn: async () => {
      const result =
        await this.authApi.merchantAdminSubscription.adminGetSubscriptionDetails(
          {
            partnerId: this._partnerId ?? 0,
          },
        );

      const items = result.data.data.map(
        (x) => new SubscriptionsDetailsModel(x),
      );

      return items;
    },
  };

  get accounts () {
    return this._accounts;
  }

  get details () {
    return this._details;
  }

  get registrationLocation () {
    return this._registrationLocation;
  }

  get sessions () {
    return this._sessions;
  }

  get kycVerifications () {
    return this._kycVerifications;
  }

  get kybVerifications () {
    return this._kybVerifications;
  }

  get kybFiles () {
    return this._kybFilesCollec;
  }

  get loans () {
    return this._loans;
  }

  get withdrawals () {
    return this._withdrawals;
  }

  get deposits () {
    return this._deposits;
  }

  get cards () {
    return this._cards;
  }

  get quests () {
    return this._quests;
  }

  get invoices () {
    return this._invoices;
  }

  get subscriptions () {
    return this._subscriptions;
  }

  get transactions () {
    return this._transactions;
  }

  get syncKybStatus () {
    return this._syncKybStatus;
  }

  get syncKybData () {
    return this._syncKybData;
  }

  get error () {
    return this._error;
  }

  get subscriptionDetails () {
    return this._subscriptionDetails;
  }

  get questSteps () {
    return this._questSteps;
  }

  private setError (error: string) {
    this._error = error;
  }

  private clearError () {
    this._error = null;
  }

  async init (userId: string, partnerId: number) {
    try {
      const userResult = await this.authApi.admin.adminGetUser(userId);
      const user = new UserModel(userResult.data);

      runInAction(() => {
        this._details = user;
        this._partnerId = partnerId;
      });

      await this.getRegistrationLocation(userId);
    } catch (err) {
      console.error(err);
    }
  }

  async save (user: UserModel) {
    try {
      await this.authApi.admin.adminEditUserInfo(user.id, user);
    } catch (err) {
      console.error(err);
    } finally {
      await this.refresh();
    }
  }

  async getRegistrationLocation (userId: string) {
    try {
      const result = await this.authApi.admin.adminGetRegistrationLocation(userId);
      const regLocation = new UserRegistrationLocationModel(result.data);

      runInAction(() => {
        this._registrationLocation = regLocation;
      });
    } catch (err) {
      console.error(err);
    }
  }

  async updateRegistrationCountry (userId: string, newCountryCode: string) {
    try {
      await this.authApi.admin.adminChangeRegistrationCountry(userId, { newCountryCode });
    } catch (err) {
      console.error(err);
    } finally {
      await this.getRegistrationLocation(userId);
    }
  }

  async updateCompanyInfo (info: CompanyInfo) {
    try {
      await this.authApi.admin.adminUpdateCompanyInfo(info.id!, info, {
        headers: { PartnerId: this._partnerId.toString() },
      });
    } catch (err) {
      console.error(err);
    } finally {
      await this.fetchKybData();
    }
  }

  async createIndividualVerification (userId: string) {
    try {
      await this.authApi.admin.adminCreateIndividualVerification(
        {
          userId,
        },
        {
          type: VerificationType.KYB_MANUAL,
        },
        {
          headers: { PartnerId: this._partnerId.toString() },
        },
      );
    } catch (err) {
      console.error(err);
    } finally {
      await this.fetchKybData();
    }
  }

  async adminUpdateCompanyInfoIndividual (info: Individual) {
    try {
      await this.authApi.admin.adminUpdateCompanyInfoIndividual(
        info.id!,
        info,
        {
          headers: { PartnerId: this._partnerId.toString() },
        },
      );
      await this.fetchKybData();
    } catch (err) {
      return (err as AxiosError<ApiErrorResponse>).response?.data;
    }
  }

  async refresh () {
    if (!this.details) {
      return;
    }

    return this.init(this.details.id, this._partnerId);
  }

  async fetchKybData () {
    if (!this._details?.companyInfoId) {
      return;
    }

    const result = await this.authApi.admin.adminGetCompanyInfo(
      this._details.companyInfoId,
      { headers: { PartnerId: this._partnerId } },
    );

    runInAction(() => {
      this._kybVerifications = new KybVerificationModel(result.data);
    });
  }

  async fetchVerificationDocuments (verificationIds: string[]) {
    const kybFiles = await Promise.all(
      verificationIds.map(async (id) => {
        const filesList =
          await this.authApi.admin.adminGetVerificationDocuments(id);

        return filesList.data?.length > 0
          ? filesList.data.map(
            (fileData) => new KybVerificationFileModel(fileData, id),
          )
          : [];
      }),
    );

    const result = kybFiles.length > 0 ? kybFiles.flat(1) : [];

    return result;
  }

  fetchListGroup = async () => {
    const result = await this.authApi.admin.adminListGroups(
      {
        size: 1000,
      },
      {
        headers: { PartnerId: this._partnerId },
      },
    );

    const items = result.data.data.map((x) => new AdminGroupModel(x));

    return items;
  };

  async loadFile (file: KybVerificationFileModel) {
    try {
      const result = await this.authApi.admin.adminDownloadDocument(
        file.verificationId,
        file.id,
        { format: 'blob' },
      );

      const blob = new Blob([result.data], { type: file.type });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.filename ?? 'file');

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);
    } catch (e) {
      console.error(e);

      this.notificationStore.notify({
        title: (e as ApiErrorResponse).message,
        type: 'error',
      });
    }
  }

  handleApiError (err: unknown) {
    if (axios.isAxiosError(err) && err.response) {
      this.setError(err.response.data.message);
    } else {
      this.setError('An unexpected error occurred.');
      console.error(err);
    }
  }

  fetchAccountBalance = async (accountId: string) => {
    try {
      const result = await this.walletApi.admin.adminGetAccountBalance(
        accountId,
      );

      return result.data.reduce((acc, item) => {
        return (
          acc +
          Number(
            this.currencyStore.exchangeCurrencies({
              from: item.currency,
              to: 'USD',
              amount: BigNumber(item.balance),
            })?.targetAmount || 0,
          )
        );
      }, 0);
    } catch (error) {
      return null;
    }
  };

  async syncVerificationKyb (verificationId: string) {
    if (this._syncKybStatus === 'processing') return;
    try {
      this.clearError();
      this._syncKybStatus = 'processing';
      const result = await this.authApi.admin.adminSyncVerification(
        verificationId,
      );

      runInAction(() => {
        this._syncKybData = result.data;
        this._syncKybStatus = 'synced';
      });
    } catch (err) {
      this.handleApiError(err);

      runInAction(() => {
        this._syncKybStatus = null;
      });
    }
  }

  async submitVerificationKyb (verificationId: string) {
    try {
      await this.authApi.admin.adminSubmitVerification(verificationId);
    } catch (err) {
      console.error(err);
    }
  }

  async getVerificationKyb (verificationId: string) {
    try {
      const result = await this.authApi.admin.adminGetVerificationByUuid(
        verificationId,
      );

      return result.data;
    } catch (err) {
      console.error(err);

      return null;
    }
  }

  async adminApproveUser (isApproved: boolean) {
    try {
      await this.authApi.admin.adminEditUserStatus(this._details?.id ?? '', {
        newUserStatus: isApproved ? UserStatus.INACTIVE : UserStatus.ACTIVE,
      });
    } catch (err) {
      console.error(err);
    } finally {
      await this.refresh();
    }
  }

  async cancelSubscription (subscriptionId: string) {
    try {
      await this.authApi.merchantAdminSubscription.adminCancelSubscription(
        subscriptionId,
      );
      this.notificationStore.notify({
        title: 'Subscription was successfully canceled',
        type: 'success',
      });

      await this._subscriptions.refresh();
    } catch (err) {
      this.notificationStore.handleApiError(err);
      console.error(err);
    }
  }

  async deleteSubscription (subscriptionId: string) {
    try {
      await this.authApi.merchantAdminSubscription.adminDeleteSubscription(
        subscriptionId,
      );
      this.notificationStore.notify({
        title: 'Subscription was successfully deleted',
        type: 'success',
      });

      await this._subscriptions.refresh();
    } catch (err) {
      this.notificationStore.handleApiError(err);
      console.error(err);
    }
  }

  async createSubscription (subscriptionId: string) {
    const data = {
      subscriptionDetailsId: subscriptionId,
      userId: this._details?.id ?? '',
    };

    try {
      await this.authApi.merchantAdminSubscription.adminCreateSubscription(
        data,
      );
      this.notificationStore.notify({
        title: 'Subscription was successfully created',
        type: 'success',
      });

      await this._subscriptions.refresh();
    } catch (err) {
      this.notificationStore.handleApiError(err);
      console.error(err);
    }
  }

  async updateUserQuestStepStatus(steps: UpdateUserQuestStepStatus[]) {
    try {
      const userQuestStepsUpdatePromises = steps.map((step) => {
        return this.questsApi.adminQuest.updateUserQuestStepStatus(step);
      });

      await Promise.all(userQuestStepsUpdatePromises);
      await this._quests.refresh();

      this.notificationStore.notify({
        title: 'Quest steps were updated',
        type: 'success',
      });
    } catch (error) {
      this.notificationStore.handleApiError(error);
      console.error(error);
    }
  }

  getQuestStepsById (id: string) {
    if (!id || !this._quests.items) return;
    const quest = this._quests.items.find((quest) => quest.quest?.id === id);
    this._questSteps = quest?.steps ?? [];
    return this._questSteps;
  }
}
