/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useState } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { Popover, TableProps } from 'antd';

import styles from './index.module.css';
import Table from 'components/core/table';
import CopyButton from 'components/core/copy-button';
import { ReactComponent as More } from 'assets/images/icons/more.svg';
import { useChangeQuestStepsStatus } from '../modal-change-step-status';

import { BadgeStatus } from 'entities/quest';
import { cutId } from 'utils/strings';
import { DATE_FORMAT, formattedDate, getRemainingDate } from 'utils/dates';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { UserQuestsModel } from 'models/UserQuestModel';
import { UpdateUserQuestStepStatus, UserQuestStep } from 'api/quests';

type Props = {
  collection: CollectionWithPages<UserQuestsModel, {}>;
  getQuestSteps: (id: string) => UserQuestStep[] | undefined;
  updateSteps: (steps: UpdateUserQuestStepStatus[]) => Promise<void>;
};

const QuestsTable = observer((props: Props) => {
  const { collection, getQuestSteps, updateSteps } = props;
  const intl = useIntl();

  const isStageEnv = window.__APP_ENV__ === 'STAGE';
  const shouldDisplayActionsColumn = isStageEnv || process.env.NODE_ENV === 'development';

  const [openedPopoverId, setOpenedPopoverId] = useState<string | null>(null);
  const { showStepsStatusModal } = useChangeQuestStepsStatus(updateSteps);

  const columns: TableProps<UserQuestsModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.quests.col.quest_id',
        defaultMessage: 'Quest ID',
      }),
      dataIndex: 'quest',
      key: 'id',
      render: (quest) => (
        <div className="flex items-center gap-2">
          <span>{cutId(quest.id)}</span>
          <CopyButton text={quest.id} />
        </div>
      ),
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.quests.col.quest_name',
        defaultMessage: 'Quest Name',
      }),
      dataIndex: 'quest',
      key: 'questName',
      render: (quest) => {
        return <span>{quest.name}</span>;
      },
      width: 180,
    },

    {
      title: intl.formatMessage({
        id: 'table.quests.col.steps_progress',
        defaultMessage: 'Steps',
      }),
      dataIndex: 'total_steps',
      key: 'total_steps',
      render: (_, record) => (
        <span>
          {record.executed_steps}/{record.total_steps}
        </span>
      ),
      width: 10,
    },

    {
      title: intl.formatMessage({
        id: 'table.quests.col.event_start',
        defaultMessage: 'Start',
      }),
      dataIndex: 'quest',
      key: 'startedAt',
      render: (quest) => {
        return <span>{formattedDate(quest.startedAt, DATE_FORMAT)}</span>;
      },
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.quests.col.event_finish',
        defaultMessage: 'Finish',
      }),
      dataIndex: 'quest',
      key: 'finishedAt',
      render: (quest) => {
        const formatted = formattedDate(quest.expiredAt, DATE_FORMAT);
        return (
          <span>{formatted === 'Invalid Date' ? 'No Date' : formatted}</span>
        );
      },
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.quests.col.event_duration',
        defaultMessage: 'Duration',
      }),
      dataIndex: 'duration',
      key: 'duration',
      render: (duration) => {
        return <span>{getRemainingDate(duration)}</span>;
      },
      width: 20,
    },

    /*    {
      title: intl.formatMessage({
        id: 'table.quests.col.triggered',
        defaultMessage: 'Triggered',
      }),
      dataIndex: 'triggeredEvent',
      key: 'triggered',
      render: (triggeredEvent) => (
        <span>{formattedDate(triggeredEvent.triggeredAt, FULL_DATE)}</span>
      ),
      width: 20,
    },*/

    /*{
      title: intl.formatMessage({
        id: 'table.quests.col.duration',
        defaultMessage: 'Duration',
      }),
      dataIndex: 'triggeredEvent',
      key: 'duration',
      render: (triggeredEvent) => (
        <span>
          {triggeredEvent.duration
            ? getRemainingDate(triggeredEvent.duration)
            : '-'}
        </span>
      ),
      width: 20,
    },*/

    {
      title: intl.formatMessage({
        id: 'table.subscriptions.col.status',
        defaultMessage: 'Status',
      }),
      dataIndex: 'status',
      key: 'status',
      render: (status) => <BadgeStatus status={status} />,
      width: 50,
    },

    /*{
      title: intl.formatMessage({
        id: 'table.companies.col.json',
        defaultMessage: 'JSON',
      }),
      dataIndex: 'triggeredEvent',
      key: 'json',
      render: (json) => (
        <Button
          variant={'secondary'}
          size={'small'}
          className='font-semibold w-28'
          disabled={isEmpty(json.eventData as Object)}
          onClick={() =>
            showJsonViewerSidebar(
              json.eventData ? JSON.stringify(json.eventData) : '{}',
              true
            )
          }
        >
          {intl.formatMessage({
            id: 'table.companies.col.checkJson',
            defaultMessage: 'Check JSON',
          })}
        </Button>
      ),
      width: 120,
    },*/
  ];

  if (shouldDisplayActionsColumn) {
    columns.push({
      title: intl.formatMessage({
        id: 'table.quests.col.actions',
        defaultMessage: 'Actions',
      }),
      key: 'actions',
      render: (_, record) => (
        <Popover
          content={
            <div className={styles.actions}>
              <button
                className={styles.action}
                onClick={() => {
                  const steps = getQuestSteps(record.quest?.id ?? '');
                  showStepsStatusModal(steps ?? [], record.id);
                  setOpenedPopoverId(null);
                }}
              >
                {intl.formatMessage({
                  id: 'table.quests.col.actions.change_steps_status',
                  defaultMessage: 'Change steps status',
                })}
              </button>
            </div>
          }
          open={openedPopoverId === record.id}
          onOpenChange={(value) => setOpenedPopoverId(value ? record.id : null)}
          trigger="click"
          arrow={false}
          placement="bottomRight"
        >
          <div className={styles['btn-more']}>
            <button>
              <More />
            </button>
          </div>
        </Popover>
      ),
      width: 10,
    });
  }

  return (
    <Table<UserQuestsModel, {}> collection={collection} columns={columns} />
  );
});

export default QuestsTable;
