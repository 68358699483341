/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { Select } from 'antd';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import joi from 'joi';

import styles from './index.module.css';
import FormField from 'components/form/form-field';
import Button from 'components/core/button';
import FormLabel from 'components/form/form-label';
import FormSelect from 'components/form/form-select';

import { GroupDto } from 'api/auth';
import { useCountries } from 'hooks/countries';
import { useIntlValidation } from 'hooks/intl/validation';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { UserModel } from 'models/UserModel';
import { AdminGroupModel } from 'models/GroupModel';
import { SelectItemModel } from 'models/form/SelectItemModel';
import { UserRegistrationLocationModel } from 'models/UserRegistrationLocationModel';

type EditUserForm = {
  firstName: string;
  lastName: string;
  phone?: string;
  email?: string;
  country?: string;
  city?: string;
  street?: string;
  subStreet?: string;
  buildingNumber?: string;
  buildingName?: string;
  flatNumber?: string;
  postCode?: string;
  groups?: GroupDto[];
  registrationCountry?: string;
};

type Props = {
  user: UserModel;
  onSubmit?: (form: EditUserForm) => void;
  onLoadGroups: () => Promise<AdminGroupModel[]>;
  partnerName: string;
  registrationLocation: UserRegistrationLocationModel | null;
}

const SidebarUserEdit = observer((props: Props) => {
  const { user, onSubmit, onLoadGroups, partnerName, registrationLocation } = props;
  const intl = useIntl();
  const { countriesArray } = useCountries();

  const [groups, setGroups] = useState<SelectItemModel<string>[]>([]);

  const loadGroups = async () => {
    const result = await onLoadGroups();

    setGroups(
      result.map(({ name, id }) => ({ id, label: name!, value: name! })),
    );
  };

  useEffect(() => {
    loadGroups();
  }, []);

  const { layoutStore } = useBaseStores();

  const { validationOptions } = useIntlValidation();

  const formScheme = useMemo(
    () =>
      joi.object({
        firstName: joi.string().empty(''),
        lastName: joi.string().empty(''),
        phone: joi.string().empty(''),
        email: joi.string().empty(''),
        country: joi.string().empty(''),
        city: joi.string().empty(''),
        street: joi.string().empty(''),
        subStreet: joi.string().empty(''),
        buildingNumber: joi.string().empty(''),
        buildingName: joi.string().empty(''),
        flatNumber: joi.string().empty(''),
        postCode: joi.string().empty(''),
        groups: joi.array(),
        registrationCountry: joi.string().empty(''),
      }),
    [],
  );

  const { handleSubmit, control, formState, setValue } =
    useForm<EditUserForm>({
      resolver: joiResolver(formScheme, validationOptions),
      mode: 'onChange',
      defaultValues: {
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        email: user.email,
        country: user.residentialAddress?.country,
        city: user.residentialAddress?.town,
        street: user.residentialAddress?.street,
        subStreet: user.residentialAddress?.subStreet,
        buildingNumber: user.residentialAddress?.buildingNumber,
        buildingName: user.residentialAddress?.buildingName,
        flatNumber: user.residentialAddress?.flatNumber,
        postCode: user.residentialAddress?.postCode,
        groups: user.groups,
        registrationCountry: registrationLocation?.locationDto?.countryIsoCode,
      },
    });

  const onSubmitForm = useCallback(
    (form: EditUserForm) => {
      onSubmit?.(form);
      layoutStore.toggleSidebar(false);
    },
    [layoutStore, onSubmit],
  );

  const { fields: groupsFields } = useFieldArray({
    control,
    name: 'groups',
  });

  const groupValue = groupsFields.map(({ name }) => name);

  const countryOptions: SelectItemModel<string>[] = countriesArray.map((country) => {
    const { alpha2Code, name } = country;
    return { id: alpha2Code, label: name, value: alpha2Code };
  });

  return (
    <div className={styles.main}>
      <div className={styles.two_fields}>
        <Controller
          control={control}
          name="firstName"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              label={intl.formatMessage({
                id: 'sidebar.edit_user.first_name',
                defaultMessage: 'First name',
              })}
              placeholder={intl.formatMessage({
                id: 'sidebar.edit_user.first_name.placeholder',
                defaultMessage: 'Enter first name...',
              })}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="lastName"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              label={intl.formatMessage({
                id: 'sidebar.edit_user.last_name',
                defaultMessage: 'Last name',
              })}
              placeholder={intl.formatMessage({
                id: 'sidebar.edit_user.last_name.placeholder',
                defaultMessage: 'Enter last name...',
              })}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />
      </div>

      <Controller
        control={control}
        name="email"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormField
            label={intl.formatMessage({
              id: 'sidebar.edit_user.email',
              defaultMessage: 'Email',
            })}
            placeholder={intl.formatMessage({
              id: 'sidebar.edit_user.email.placeholder',
              defaultMessage: 'Enter email...',
            })}
            value={value}
            onChange={onChange}
            error={error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="phone"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormField
            label={intl.formatMessage({
              id: 'sidebar.edit_user.phone',
              defaultMessage: 'Phone',
            })}
            placeholder={intl.formatMessage({
              id: 'sidebar.edit_user.phone.placeholder',
              defaultMessage: 'Enter phone number...',
            })}
            value={value}
            onChange={onChange}
            error={error?.message}
          />
        )}
      />

      <div className={styles.two_fields}>
        <Controller
          control={control}
          name="country"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              label={intl.formatMessage({
                id: 'sidebar.edit_user.country',
                defaultMessage: 'Country',
              })}
              placeholder={intl.formatMessage({
                id: 'sidebar.edit_user.country.placeholder',
                defaultMessage: 'Enter country...',
              })}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="city"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              label={intl.formatMessage({
                id: 'sidebar.edit_user.city',
                defaultMessage: 'City',
              })}
              placeholder={intl.formatMessage({
                id: 'sidebar.edit_user.city.placeholder',
                defaultMessage: 'Enter city...',
              })}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />
      </div>

      <Controller
        control={control}
        name="street"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormField
            label={intl.formatMessage({
              id: 'sidebar.edit_user.street',
              defaultMessage: 'Street',
            })}
            placeholder={intl.formatMessage({
              id: 'sidebar.edit_user.street.placeholder',
              defaultMessage: 'Enter street...',
            })}
            value={value}
            onChange={onChange}
            error={error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="subStreet"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormField
            label={intl.formatMessage({
              id: 'sidebar.edit_user.subStreet',
              defaultMessage: 'Substreet',
            })}
            placeholder={intl.formatMessage({
              id: 'sidebar.edit_user.subStreet.placeholder',
              defaultMessage: 'Enter substreet...',
            })}
            value={value}
            onChange={onChange}
            error={error?.message}
          />
        )}
      />

      <div className={styles.two_fields}>
        <Controller
          control={control}
          name="buildingNumber"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              label={intl.formatMessage({
                id: 'sidebar.edit_user.buildingNumber',
                defaultMessage: 'Building number',
              })}
              placeholder={intl.formatMessage({
                id: 'sidebar.edit_user.buildingNumber.placeholder',
                defaultMessage: 'Enter building number...',
              })}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="buildingName"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              label={intl.formatMessage({
                id: 'sidebar.edit_user.buildingName',
                defaultMessage: 'Building name',
              })}
              placeholder={intl.formatMessage({
                id: 'sidebar.edit_user.buildingName.placeholder',
                defaultMessage: 'Enter building name...',
              })}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />
      </div>

      <div className={styles.two_fields}>
        <Controller
          control={control}
          name="flatNumber"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              label={intl.formatMessage({
                id: 'sidebar.edit_user.flatNumber',
                defaultMessage: 'Flat number',
              })}
              placeholder={intl.formatMessage({
                id: 'sidebar.edit_user.flatNumber.placeholder',
                defaultMessage: 'Enter flat number...',
              })}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="postCode"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              label={intl.formatMessage({
                id: 'sidebar.edit_user.postCode',
                defaultMessage: 'Post Code',
              })}
              placeholder={intl.formatMessage({
                id: 'sidebar.edit_user.postCode.placeholder',
                defaultMessage: 'Enter post code...',
              })}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />
      </div>

      <div className={styles.field}>
        <FormLabel
          text={intl.formatMessage({
            id: 'sidebar.edit_user.groups',
            defaultMessage: 'Groups',
          })}
        />
        <Select
          size="large"
          mode="multiple"
          options={groups}
          value={groupValue}
          onChange={(value) => {
            setValue(
              'groups',
              value.map((item) => ({ name: item, partner: partnerName })),
            );
          }}
        />
      </div>

      <Controller
        control={control}
        name='registrationCountry'
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <FormSelect
              label={intl.formatMessage({
                id: 'sidebar.edit_user.registration_country',
                defaultMessage: 'Registration country',
              })}
              placeholder={intl.formatMessage({
                id: 'sidebar.edit_user.registration_country.placeholder',
                defaultMessage: 'Choose registration country...',
              })}
              options={countryOptions}
              value={countryOptions.find((item) => item.value === value)}
              onChange={(value) => onChange(value?.value as string)}
              error={error?.message}
            />
          );
        }}
      />

      <div className={styles.actions}>
        <Button
          variant="primary"
          onClick={handleSubmit(onSubmitForm)}
          disabled={!formState.isValid}
        >
          {intl.formatMessage({
            id: 'sidebar.edit_user.submit',
            defaultMessage: 'Submit',
          })}
        </Button>
      </div>
    </div>
  );
});

export const useSidebarUserEdit = ({
  onChange,
  onLoadGroups,
  onChangeRegistrationCountry,
}: {
  onChange: (user: UserModel) => void;
  onLoadGroups: () => Promise<AdminGroupModel[]>;
  onChangeRegistrationCountry: (userId: string, newCountry: string | undefined) => void;
}) => {
  const intl = useIntl();

  const { layoutStore, partnerStore } = useBaseStores();

  const onFormSubmit = useCallback(
    (user: UserModel, form: EditUserForm) => {
      onChange({
        ...user,
        firstName: form.firstName,
        lastName: form.lastName,
        phone: form.phone,
        email: form.email,
        groups: form.groups,
        residentialAddress: {
          state: '',
          country: form.country,
          town: form.city,
          street: form.street,
          subStreet: form.subStreet,
          buildingNumber: form.buildingNumber,
          buildingName: form.buildingName,
          flatNumber: form.flatNumber,
          postCode: form.postCode,
        },
      });

      onChangeRegistrationCountry(user.id, form.registrationCountry);
    },
    [onChange, onChangeRegistrationCountry],
  );

  const showSidebar = useCallback(
    (user: UserModel, registrationLocation: UserRegistrationLocationModel | null) => {
      layoutStore.toggleSidebar(
        true,
        intl.formatMessage({
          id: 'sidebar.edit_info',
          defaultMessage: 'Edit info',
        }),
        <SidebarUserEdit
          user={user}
          partnerName={partnerStore.currentPartner?.name!}
          registrationLocation={registrationLocation}
          onSubmit={(form) => onFormSubmit(user, form)}
          onLoadGroups={onLoadGroups}
        />,
      );
    },
    [intl, layoutStore, onFormSubmit, onLoadGroups],
  );

  return {
    showEditUserSidebar: showSidebar,
  };
};
